import Container from "./Container";
import Link from "next/link";
import SvgLogo from "./svg/SvgLogo";
import {useRouter} from "next/router";
import localized from "../locale/locales";
import {getFullYear} from "../helpers/date";

export default function Footer() {
    const {locale} = useRouter();
    const {footer: lang, common} = localized[locale];

    const footerLinks = [
        {
            href: '/faq',
            label: common.pages.faq
        },
        {
            href: '/how-it-works',
            label: common.pages.howItWorks
        },
    ];

    if (locale !== 'en') {
        footerLinks.push({
            href: '/register',
            label: common.pages.register
        });
    }

    return (
        <footer className="bg-gradient-to-tr from-lilac-800 to-lilac-500 text-white py-9">
            <Container>
                <div className="flex flex-col md:flex-row gap-8 lg:gap-0 justify-between pb-7 border-b border-white/20">
                    <div className="flex flex-col gap-4">
                        <Link href="/">
                            <a className="inline-flex">
                                <SvgLogo width="166" height="26"/>
                            </a>
                        </Link>
                        <p className="text-white/50 text-xs max-w-[100ch] leading-[1.5]">
                            {lang.body}
                        </p>
                    </div>
                    <div className="flex flex-col md:items-end">
                        {footerLinks.map(({href, label}, key) => {
                            return (
                                <Link href={href} key={`FooterLink: ${key}`}>
                                    <a className="uppercase underline mouse-hover:no-underline inline-flex">
                                        {label}
                                    </a>
                                </Link>
                            )
                        })}
                    </div>
                </div>

                <div className="pt-7 md:pt-3 flex flex-col md:flex-row gap-7 md:gap-0 justify-between">
                    <div>
                        <p className="text-white/60">
                            &copy; {getFullYear()} {common.address[0] || ''}<br/>
                            {common.address[1] || ''}, {common.address[2] || ''}
                        </p>
                    </div>
                    <div className="flex flex-col md:items-end">
                        <a href={`tel:${common.phone.split(' ').join('')}`}
                           className="text-white/60 mouse-hover:text-white transition-color duration-200 inline-flex">{common.phone}</a>
                        <a href={`mailto:${common.email}`}
                           className="text-white/60 mouse-hover:text-white transition-color duration-200 inline-flex">{common.email}</a>
                    </div>
                </div>
            </Container>
        </footer>
    )
}